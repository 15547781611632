$btnGray: #82868b;
$btnPurple: #7367f0;

// body::-webkit-scrollbar {
//   width: 1em;
// }
// body::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }
// body::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }
body {
  z-index: 99999999999999999999999999999 !important;
  font-family: 'Poppins' !important;
}

*::-webkit-scrollbar {
  width: 4px !important;
  height: 5px;
  /* width of the entire scrollbar */
}

// *::-webkit-scrollbar-track {
//   /* color of the tracking area */
// }

*::-webkit-scrollbar-thumb {
  background-color: #b4b7bd !important;
  /* color of the scroll thumb */
  border-radius: 20px !important;
  /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}

.main-bg-color {
  background-color: #7367f0 !important;
}

#customTablePages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.chevron-active {
  border: none;
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: 50%;
  color: white;
  background-color: $btnPurple;
}

.chevron-passive {
  border: none;
  padding: 5px;
  margin: 5px;
  background-color: white;
  border-radius: 50%;
  color: white;
  background-color: $btnGray;
}

.chevron-active:active {
  padding: 4px !important;
}

// .react-select {
//   z-index: 500 !important;
// }
.submain__provider {
  padding: 100px;
  background-color: #212648 !important;
}

// .react-select {
//   z-index: 999999 !important;
// }
.filter_card {
  padding: 5px !important;
  // box-shadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px";
}

.filter_space {
  display: flex;
  justify-content: space-between;
}

.filter_scroll {
  display: block;
  height: 225px;
  overflow: auto;
}

.filter_arrow {
  font-size: large;
}

.filter_arrow_selected {
  font-size: large;
  color: #7367f0;
}

.filter_arrow:hover {
  color: #7367f0;
}

.modal-content {
  width: 100vh !important;
}

.small_label {
  font-size: 10px !important;
}

.cardbody-scroll {
  height: 550px !important;
  overflow-y: scroll !important;
}

.cursor-pointer {
  cursor: pointer;
}

.primary-border {
  border: 2px solid #403a85;
}

.primary-checkbox {
  accent-color: #7267ef;
}

.styles_react-code-input__CRulA>input {
  background-color: #283046 !important;
  color: white !important;
}

.styles_react-code-input-container__tpiKG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto !important;
  margin-top: 52px;
}

.sms_preview_message_bg {
  border: 1px solid !important;
  padding: 10px;
  height: 150px;
  overflow-y: scroll;
  margin-top: 20px;
  word-break: break-all;
}

.font-icon {
  font-size: 20px;
}

.text-error {
  color: red;
  font-size: 12px;
}

.phone-input {
  input {
    border: none !important;
  }
}

.PhoneInputInput:focus-visible {
  flex: 1;
  min-width: 0;
  border: none;
  outline: none;
}

.react-tel-input .form-control {
  width: 100% !important;
}

@media screen and (min-width: 800px) {
  .filter_button {
    position: fixed;
    margin-top: -22px;
    bottom: 30px;
  }
}

.modal-dialog.modal-xl.modal-dialog-centered {
  width: 3000px;
  max-width: 96%;
}

.btn-primary {
  border: none;
  background: #E2E7EA !important;
  transition: background-color 2s;
  box-shadow: none !important;
  color: #F78620 !important;
}

.btn-primary:hover {
  border: none;
  background: #E2E7EA !important;
  box-shadow: none !important;
  color: #F78620 !important;
}

.main-menu.menu-dark .navigation>li ul .active {
  background: #E2E7EA !important;
  box-shadow: none !important;
  color: #F78620 !important;
}

.main-menu.menu-light .navigation>li ul .active {
  background: #E2E7EA !important;
  box-shadow: none !important;
  color: #F78620 !important;
}

.vertical-layout .main-menu .navigation a.active {
  background: #E2E7EA !important;
  box-shadow: none !important;
  color: #F78620 !important;
}

@media (min-width: 0px) and (max-width: 1024px) {
  .filter_area {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .filter_btn {
    display: none !important;
  }
}

.filter_month {
  width: 150px !important;
  height: 45px !important;
  color: #fe7174 !important;
  background-color: transparent !important;
  border: 1px solid #fe7174 !important;
  box-shadow: none;
}

.filter_month:focus {
  background-color: #ffebeb !important;
}

.filter_week {
  width: 150px !important;
  height: 45px !important;
  color: #328d69 !important;
  background-color: transparent !important;
  border: 1px solid #328d69 !important;
}

.filter_week:focus {
  background-color: #cfe6d6 !important;
}

.filter_day {
  width: 150px !important;
  height: 45px !important;
  color: #65a9f3 !important;
  background-color: transparent !important;
  border: 1px solid #65a9f3 !important;
}

.filter_day:focus {
  background-color: #e2f3fd !important;
}

.filter_manuel {
  width: 150px !important;
  height: 45px !important;
  color: #db945c !important;
  background-color: #fef0e5 !important;
  border: 1px solid #db945c !important;
  font-size: 14px;
}

.filter_manuel:focus {
  background-color: #fef0e5 !important;
}

.filter_month_modal {
  width: 100% !important;
  height: 55px !important;
  color: #fe7174 !important;
  background-color: transparent !important;
  border: 1px solid #fe7174 !important;
  box-shadow: none;
}

.filter_month_modal:focus {
  background-color: #ffebeb !important;
}

.filter_week_modal {
  width: 100% !important;
  height: 55px !important;
  color: #328d69 !important;
  background-color: transparent !important;
  border: 1px solid #328d69 !important;
}

.filter_week_modal:focus {
  background-color: #cfe6d6 !important;
}

.filter_day_modal {
  width: 100% !important;
  height: 55px !important;
  color: #65a9f3 !important;
  background-color: transparent !important;
  border: 1px solid #65a9f3 !important;
}

.filter_day_modal:focus {
  background-color: #e2f3fd !important;
}

.filter_yesterday_modal {
  width: 100% !important;
  height: 55px !important;
  color: #db945c !important;
  background-color: #fef0e5 !important;
  border: 1px solid #db945c !important;
}

.filter_yesterday_modal:focus {
  background-color: #fef0e5 !important;
}

.cancel-link {
  button:hover {
    background-color: white !important;
    color: black !important;
    transition: 1s;
  }
}