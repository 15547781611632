.phone-container {
    position: relative;
    height: 100%;
    width: 100%;

    .phone {
        top: 0px;
        padding: 30px;
        padding-top: 20px;
        width: 100%;
        height: 100%;
        position: absolute;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        // background-color: red;
        // opacity: 0.4;
    }
}

.is-sticky {
    position: fixed;
    height: 80vh;
    width: 100%;
    //max-width: 300px;
    top: 10px;
    z-index: 999;
    //animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

@media screen and (max-width: 768px) {
    .hr-container {
        display: none !important;
    }
}